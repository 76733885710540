@charset "UTF-8";
/**
 * Print Stylesheet fuer Deinewebsite.de
* @version         1.0
* @lastmodified    16.06.2016
*/
@media print {
  /* Inhaltsbreite setzen, Floats und Margins aufheben */
  /* Achtung: Die Klassen und IDs variieren von Theme zu Theme. Hier also eigene Klassen setzen */
  #content, #page {
    width: 100%;
    margin: 0;
    float: none; }
  /** Seitenränder einstellen */
  @page {
    margin: 2cm; }
  /* Font auf 16px/13pt setzen, Background auf Weiß und Schrift auf Schwarz setzen.*/
  /* Das spart Tinte */
  body {
    font: 12pt Georgia, "Times New Roman", Times, serif;
    line-height: 1.3;
    background: #fff !important;
    color: #000;
    /* Alle Seitenumbrüche definieren */
    /* Linkfarbe und Linkverhalten darstellen */
    /**
   * Eingebundene Videos verschwinden lassen und den Whitespace der iframes auf null reduzieren.
   */
    /* Unnötige Elemente ausblenden für den Druck */
    /* Wichtige Elemente definieren */
    /* Zeichensatz fuer Code Beispiele */ }
    body h1 {
      font-size: 24pt;
      font-weight: bold; }
    body h2, body h3, body h4 {
      font-size: 14pt;
      margin-top: 25px;
      font-weight: bold; }
    body a {
      page-break-inside: avoid; }
    body blockquote {
      page-break-inside: avoid; }
    body h1, body h2, body h3, body h4, body h5, body h6 {
      page-break-after: avoid;
      page-break-inside: avoid; }
    body img {
      page-break-inside: avoid;
      page-break-after: avoid; }
    body table, body pre {
      page-break-inside: avoid; }
    body ul, body ol, body dl {
      page-break-before: avoid; }
    body a:link, body a:visited, body a {
      background: transparent;
      color: #520;
      font-weight: bold;
      text-decoration: underline;
      text-align: left; }
    body a {
      page-break-inside: avoid; }
    body a:after > img {
      content: ""; }
    body .entry iframe, body ins {
      display: none;
      width: 0 !important;
      height: 0 !important;
      overflow: hidden !important;
      line-height: 0pt !important;
      white-space: nowrap; }
    body .embed-youtube, body .embed-responsive {
      position: absolute;
      height: 0;
      overflow: hidden; }
    body #header-widgets, body nav, body aside.mashsb-container,
    body .sidebar, body .mashshare-top, body .mashshare-bottom,
    body .content-ads, body .make-comment, body .author-bio,
    body .heading, body .related-posts, body #decomments-form-add-comment,
    body #breadcrumbs, body #footer, body .footer, body .post-byline, body .meta-single,
    body .site-title img, body .post-tags, body .readability, body .header-container,
    body .contact-box, body video, body embed, body .mobile-header, body .breadcrumb {
      display: none; }
    body p, body address, body li, body dt, body dd, body blockquote {
      font-size: 100%; }
    body code, body pre {
      font-family: "Courier New", Courier, mono; }
    body ul, body ol {
      list-style: square;
      margin-left: 18pt;
      margin-bottom: 20pt; }
    body li {
      line-height: 1.6em; }
  a[href]:after {
    content: none !important; } }
